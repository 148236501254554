<template>
  <div>
    <b-card title="Create Awesome 🙌">
      <b-card-text>This is your second page.</b-card-text>
      <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups
        jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.
      </b-card-text>
    </b-card>
    <div class="mb-3">
      <b-button
        v-b-toggle="'sample-' + id"
        @click.prevent
      >
        Toggle Collapse
      </b-button>
    </div>

    <b-collapse :id="'sample-' + id">
      <b-card title="Collapsible card">
        Hello world!
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardText, BCollapse, VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCollapse,
    VBToggle,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      id: 123,
    }
  },
}
</script>
